/* ==========================================================================
   Including the external CSS files
   ========================================================================== */

body {
    margin:0;
    // background-color: var(--color-background);
    overscroll-behavior: none;
}
* {
    user-select: none;
    outline: none;
    box-shadow: none;
    touch-action: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: none;
}
html {
    -webkit-text-size-adjust: none
}

/* ==========================================================================
   LESS VARIABLES
   ========================================================================== */
//Global colors
@primary: #1d3544;
@secondary: #f78d1e;

//Text colors
@TWhite: #fff;
@TDark: #015a8e;


/* ==========================================================================
   PMC's custom styles
   ========================================================================== */
body {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100%;
}
#canvas {
    min-height: 100vh;
    text-align: center;
    margin:auto;
    width: 100%;
    box-sizing: border-box;


    #myProgress {
        position: absolute;
        width: calc(100% - 20px);
        left: 10px;
        z-index: 1;
        height: 40px;
        border: 4px solid @primary;
        border-radius: 5px;
        box-sizing: border-box;
        overflow: hidden;
    }

    #myBarBackground {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ED8B00;
        z-index: -2;
        box-sizing: border-box;
    }

    #myBar {
        position: absolute;
        width: 0%;
        height: 100%;
        // height: 20px;
        background-color: #ED8B00;
        transition: width .5s linear;
        overflow: hidden;

        z-index: -1;

        &.fastBar{
            transition: width .075s linear;
        }
        &.emptyBar{
            transition: width .01s linear;
        }
        &.refillBar{
            transition: width 0.10s linear;
        }
        &.disabled {
            transition: none;
        }
    }

    #score {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 2;
    }

    .cardsContainer {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: column;
        &>div {
            display: flex;
            flex-flow: row;
            justify-content: center;
        }


        margin:auto;
		::selection {
			background: none;
		}

        .container {
            width: 60px;
			height: 60px;
            position: relative;
            perspective: 800px;
            margin: 4px;
            cursor: pointer;
            -webkit-perspective: 1000;
            -webkit-backface-visibility: hidden;

            .card {
                width: 100%;
                height: 100%;
                transition: transform 0.5s;
                transform-style: preserve-3d;

            }

            @keyframes match {
                50% {
                    transform:scale(1.1) rotateY( 180deg );
                }
                100% {
                    transform:scale(1) rotateY( 180deg ) ;
                }
            }

            .card.flipped {
                transform: rotateY( 180deg );

                &.match{
                    animation: match 0.25s ease-in-out;
                    animation-delay: .5s;
                }
            }

            .card .front {
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                backface-visibility: hidden;

				// border: 3px solid black;
				border-radius: 10px;
				overflow: hidden;
                // border: 3px solid black;
				box-sizing: border-box;
				// box-shadow: 5px 5px #00000054;

                pointer-events: none;
                object-fit: fill;

                .image {
					width: 100%;
					display: block;
					height: 100%;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					pointer-events: none;
				}
            }

            .card .back {
                transform: rotateY( 180deg );
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				border: 3px solid black;
				overflow: hidden;
				box-sizing: border-box;
				background-size: 90%;
                pointer-events: none;
                object-fit: contain;

                backface-visibility: hidden;

                // &.match{
                //     animation: match 0.5s ease-in-out;
                // }

				.image {
					width: 100%;
					display: block;
					height: 100%;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					pointer-events: none;
				}
            }
        }
    }
}





/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}
